import React from "react";
import PropTypes from "prop-types";

/**
 * You will Learn Section (mostly used in the header)
 * @param learnItems[{ text: string }] - Array of objects containg text of learning
 * @param title - Title of the section. Defaults to "In this guide you\'ll learn:"
 */
const YouWillLearn = ({ learnItems, title }) => {
  return (
    <section className="you-will-learn">
      <h3 className="you-will-learn__title">{title}</h3>
      <ol className="you-will-learn__list">
        {learnItems.map((learning, index) => (
          <li key={index}>{learning.text}</li>
        ))}
      </ol>
    </section>
  );
};

YouWillLearn.propTypes = {
  learnItems: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired
    })
  )
};

YouWillLearn.defaultProps = {
  title: "In this guide you'll learn:"
};

export default YouWillLearn;
