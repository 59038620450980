import React, {useEffect} from "react";
import Layout from "../layouts/Layout";
import { graphql } from "gatsby";
import { Col, Container, Row } from "react-bootstrap";
import Author from "../components/Author";
import Toc from "../components/Toc";
import SocialShare from "../components/SocialShare";
import RelatedGuides from "../components/RelatedGuides";
import YouWillLearn from "../components/YouWillLearn";
import Clipboard from "clipboard";
import accordion from "../helper/accordion";

const Guides = ({ pageContext, data, location }) => {
  useEffect(() => {
    addScrollToH2();
    addCopyToH2();
    accordion.init();
  });

  const addScrollToH2 = () => {
    if (window.location.href.includes("#")) {
      const id = window.location.href.split("#")[1];
      const element = document.querySelector(`a[href='#${id}']`);

      // doesnt work without setTimeout (content needs a bit to render and messes up scrollTop position of element)
      setTimeout(() => {
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest"
        });
      }, 200);
    }
  };

  const addCopyToH2 = () => {
    new Clipboard("article .content .guides-section-link", {
      text: function(trigger) {
        return trigger.href;
      }
    });
  };

  const guide = data.guide;
  const category = guide?.post_categories[0];
  const authorImage =
    guide?.post_author?.acf?.image?.localFile?.childImageSharp?.fluid;
  const featuredImage = guide?.featured_media?.localFile?.childImageSharp?.fluid;
  const stringTranslations = pageContext.stringTranslations;
  const authorName =
    guide.post_author.wordpress_id === 12 ?
    stringTranslations["The Amazon Associates Team"] :
    guide.post_author.name;

  return (
    <Layout
      context={pageContext}
      className="guides-page"
      useContainer={false}
      location={location}
      progressBarTarget="article"
    >
      <article className="article">
        <header>
          <Container>
            <Row>
              <Col
                lg={{ span: 5, offset: 1 }}
                className="d-flex flex-column justify-content-center align-items-start"
              >
                {category?.name && <p className="roofline" dangerouslySetInnerHTML={{__html: category.name}} />}
                <h1 dangerouslySetInnerHTML={{__html: guide.post_title}} />

                {(guide?.acf?.excerpt || guide.post_excerpt) && (
                  <div
                    className="intro"
                    dangerouslySetInnerHTML={{
                      __html: guide?.acf?.excerpt || guide.post_excerpt
                    }}
                  />
                )}

                {guide?.acf?.learn_section && (
                  <YouWillLearn learnItems={guide.acf.learn_section} title={stringTranslations["In this guide you'll learn:"]} />
                )}

                <Author
                  image={authorImage?.src}
                  imageSrcSet={authorImage?.srcSet}
                  name={authorName}
                >
                  {stringTranslations["Last updated"]} {guide.post_date} • {guide.time_to_read.minutes}{" "} {stringTranslations["Min Read"]}
                </Author>
              </Col>
              {featuredImage && (
                <Col lg={6} className="d-flex justify-content-center align-items-center">
                  <img
                    srcSet={featuredImage?.srcSet}
                    className="featured-image"
                    src={featuredImage?.src}
                    alt={guide.post_title}
                  />
                </Col>
              )}
            </Row>
          </Container>
        </header>

        <Container>
          <Row>
            <Col lg={{ span: 6, offset: 1 }}>
              <div
                className="content"
                dangerouslySetInnerHTML={{ __html: guide.post_content }}
              />
            </Col>
            <Col lg={{ span: 3, offset: 1 }}>
              <Toc enter={150} title={stringTranslations["Contents"]} />
            </Col>
          </Row>
        </Container>

        <Container className="guide-footer">
          <Row>
            <Col lg={{ span: 6, offset: 1 }}>
              <SocialShare
                likeCount={
                  Math.round(Math.random() * 1000) /* TODO fix random likes */
                }
                path={guide.path}
                postId={guide.wordpress_id}
                postTitle={guide.post_title}
              />
            </Col>
          </Row>

          { guide.post_author && (
            <Row>
              <Col lg={{ span: 6, offset: 1 }}>
                <Author
                  image={authorImage?.src}
                  imageSrcSet={authorImage?.srcSet}
                  name={authorName}
                  variant="big"
                  by={stringTranslations["by"]}
                >
                  {guide.post_author?.description}
                </Author>
              </Col>
            </Row>
          )}
        </Container>

        {guide?.acf?.related_guides && (
          <RelatedGuides guides={guide.acf.related_guides} title={stringTranslations["Related Guides"]} />
        )}
      </article>
    </Layout>
  );
};

export const query = graphql`
  query GuidesQuery($id: Int!, $languageCode: String!) {
    guide: wordpressWpGuides(
      wordpress_id: { eq: $id }
      language: { language_code: { eq: $languageCode } }
    ) {
      post_title
      post_content
      path
      wordpress_id
      post_date(locale: $languageCode, formatString: "MMMM D, YYYY")
      post_author {
        description
        name
        wordpress_id
        acf {
          image {
            localFile {
              childImageSharp {
                fluid(jpegQuality: 100, maxWidth: 980) {
                  srcSet
                  src
                }
              }
            }
          }
        }
      }
      time_to_read {
        word_count
        minutes
      }
      featured_media {
        localFile {
          childImageSharp {
            fluid(jpegQuality: 100, maxWidth: 980) {
              srcSet
              src
            }
          }
        }
      }
      post_excerpt
      language {
        locale
        language_code
        root_path
        locale_native_name
      }
      post_categories {
        path
        name
      }
      acf {
        learn_section {
          text
        }
        excerpt
        related_guides {
          image {
            alt
            url {
              localFile {
                childImageSharp {
                  fluid(jpegQuality: 70, maxWidth: 980) {
                    srcSet
                  }
                }
              }
            }
          }
          path
          post_name
          post_excerpt
        }
      }
    }
  }
`;

export default Guides;
