import React from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import CenteredImage from "./CenteredImage";
import { Link } from "gatsby";
import {makeExcerpt} from "../helper/util";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css"

/**
 * Displays Related Guides, mostly used at the bottom of a page, with cards representing the guide.
 *
 * @param guides - Guides array coming from acf fields
 */
const RelatedGuides = ({ guides, title }) => {
  const [sliderRef] = useKeenSlider({
    slidesPerView: 3,
    mode: "free-snap",
    spacing: 8,
    loop: false,
    centered: false,
    autoAdjustSlidesPerView: false,
    breakpoints: {
      '(max-width: 1280px)': {
        slidesPerView: 1.3,
        centered: true,
      },
    },
  })

  if (!guides || guides.length === 0) return null;

  return (
    <div className="related-guides">
      <Container fluid>
        <Row>
          <Col>
            <h5 className="related-guides__title">{title}</h5>
            <div className="keen-slider related-guides__items" ref={sliderRef}>
              {guides.map((guide) => (
                <div
                  className={`keen-slider__slide`}
                  key={guide.post_name}
                >
                  <Link to={guide.path} className="related-guides__link">
                    <Card className="link related-guides__card">
                      <CenteredImage
                        alt={
                          guide?.image?.alt ||
                          guide.post_title ||
                          guide.post_name
                        }
                        source={
                          guide?.image?.url?.localFile?.childImageSharp?.fluid
                            .srcSet
                        }
                      />
                      <Card.Body>
                        <Card.Title>{guide.post_name}</Card.Title>
                        {guide.post_excerpt && (
                          <Card.Text
                            dangerouslySetInnerHTML={{
                              __html: makeExcerpt(guide.post_excerpt, 160)
                            }}
                          />
                        )}
                      </Card.Body>
                    </Card>
                  </Link>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

RelatedGuides.defaultProps = {
  title: "Related Guides"
}

export default RelatedGuides;
